<template>
  <UiTourDriver
    ref="driver"
    :tour="TOURS_IDS.NAVIGATION_MENU"
    :highlight
    :type="TOURS_TYPES.UPDATE"
    @destroyed="useMittEvent('tour:navigationMenu')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

onMounted(() => {
  useMittListen('tour:navigationMenu', () => {
    if (driver.value?.driverObj) {
      driver.value.startTour()
    }
  })
})

const highlight = ref<DriveStep>({
  element: '#toggle-navigation-menu',
  popover: {
    title: 'Hide navigation',
    description: `You can now easily hide the navigation by clicking this button. The button will remain fixed on the left side. When you hover over it, you’ll be able to navigate between sections. To expand the navigation again, simply click the button, and it will slide back into view.`,
    nextBtnText: 'Okay, I’ll check!',
    showButtons: ['next'],
    onNextClick: () => {
      if (driver.value?.driverObj) {
        driver.value.driverObj.destroy()
      }
    },
  },
})

onUnmounted(() => {
  useMittRemove('tour:navigationMenu')
})
</script>

<style scoped lang="scss">
:deep() {
  .driver-popover-next-btn {
    width: 100% !important;
  }
}
</style>
